import TranslationStepSwitch from './TranslationStepSwitch';
import { useRef, useState } from 'react';
import TranslateTextPage from './TranslateTextPage';
import AddImagePage from './AddImagePage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TranslateSelector } from '../../redux/translation/selector';
import { setImagePageVisibility } from '../../redux/translation/actions';

const TranslationFlowPage = () => {
  const dispatch: any = useDispatch();
  const [outputText, setOutputText] = useState('');
  const showImagePage = useSelector(
    (state: any) => TranslateSelector(state).showImagePage,
    shallowEqual
  );

  let outputTextValue = useRef({ text: '' });
  return (
    <div>
      <TranslationStepSwitch switchStep={(value) => dispatch(setImagePageVisibility(value))} addImage={showImagePage} />
      {showImagePage ?
        <AddImagePage
          outputText={outputText}
          setOutputText={setOutputText}
          outputTextValue={outputTextValue}
        /> :
        <TranslateTextPage
          outputText={outputText}
          setOutputText={setOutputText}
          outputTextValue={outputTextValue}
        />}
    </div>
  );
}


export default TranslationFlowPage;