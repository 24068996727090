import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { Container, Grid } from '@mui/material';
import theme from '../../theme/themeConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { UserSelector } from '../../redux/user/selector';
import TextEditorComp from '../../coreUI/textEditorComp/TextEditorComp';
import Tooltip from '@mui/material/Tooltip';
import {
  convertParagraphAndTable,
  editorValueClear,
  editorValueUpdate,
} from '../TextareaField/helper';
import InfoIcon from '../TextareaField/translationOutput/InfoIcon';
import { TranslateSelector } from 'src/redux/translation/selector';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { HistorySelector } from '../../redux/translationHistory/selector';
import { LexicalEditor } from 'lexical';
import { AddImageSelector } from '../../redux/addImage/selector';

type Props = {
  handleOutputLanguageTypeChange: (localStorageValue: string) => void;
  outputLanguage: any;
  showHighlight: boolean;
  open: any;
  outputRefEditor: any;
  showTooltipOutput: any;
  loadingTranslation: boolean;
  setOutputText: any;
  outputText: string;
  outputTextValue: any;
  placeholder: string;
  jobId: string;
  isUpdated: boolean | undefined;
  setIsUpdated: Dispatch<SetStateAction<boolean>>;
};

export let imageEditor: null | LexicalEditor = null;

const AddImageTextArea = (props: Props) => {
  // const dispatch: any = useDispatch();
  const dispatch = useDispatch()
  const { t } = useTranslation(['easy_language', 'common', 'A11y'], {
    nsMode: 'fallback',
  });
  // const [outputFixedValue, setOutputFixedValue] = useState('');
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  // const dispatch: any = useDispatch();
  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));
  const hasGroup = useSelector(
    (state: any) => UserSelector(state).hasGroup,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const historyJob = useSelector(
    (state: any) => HistorySelector(state).historyJob,
    shallowEqual
  );

  // const selectedOutputNode = useSelector(
  //   (state: any) => TranslateSelector(state).selectedOutputNode,
  //   shallowEqual
  // );

  // const userDetails = useSelector(
  //   (state: any) => UserSelector(state).userDetails,
  //   shallowEqual
  // );

  useEffect(() => {
    imageEditor = props.outputRefEditor;
  }, [props.outputRefEditor]);

  const editorValue = useCallback(
    (
      text,
      refEditor = props.outputRefEditor,
      id = 'translation-input',
      firstTimeCall = false
    ) => {
      // fix for extracting text from file
      if (id === 'translation-input' && text) {
        editorValueClear(refEditor, dispatch);
        editorValueUpdate(text, refEditor, id);
      }
      // id !== 'translation-input' is for when trigger new translation my input text stay
      if (id !== 'translation-input' || historyJob || firstTimeCall) {
        editorValueClear(refEditor, dispatch);
        editorValueUpdate(text, refEditor, id);
      }
    },
    [historyJob, props.outputRefEditor]
  );

  const [isVisible, setIsVisible] = useState(false); // Track visibility state
  const visibilityRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Observe visibility relative to the viewport
        rootMargin: '0px', // Margin around the root
        threshold: 0.1, // Percentage of component visibility to trigger (10%)
      }
    );

    if (visibilityRef.current) {
      observer.observe(visibilityRef.current); // Start observing the component
    }

    return () => {
      if (visibilityRef.current) {
        observer.unobserve(visibilityRef.current); // Cleanup observer
      }
    };
  }, []);

  // useEffect(() => {
  //   convertParagraphAndTable(props.outputRefEditor, 3);
  // }, [props.outputRefEditor]);

  useEffect(() => {
    if (transjob.jobId === '-1') {
      editorValueClear(props.outputRefEditor, dispatch);
    }
  }, [isVisible]);

  const tableImages = useSelector(
    (state: any) => AddImageSelector(state).tableNodesWithImages,
    shallowEqual
  );

  useEffect(() => {
    if (!isVisible) return;

    if (historyJob) {
      // get the ref element to input and output
      const { simpletextuseredited, simpletext, originaltext } = historyJob;
      let text = isEmpty(simpletextuseredited)
        ? simpletext
        : simpletextuseredited;
      editorValue(text, props.outputRefEditor, 'translation-output');
    }

    if (!historyJob && transjob.jobId === '-1') {
      editorValue('', props.outputRefEditor, 'translation-output');
    }

    // check if translation job is not  empty
    if (!historyJob && transjob.jobId !== '-1') {
      const { simpleText } = transjob;
      editorValue(simpleText, props.outputRefEditor, 'translation-output');
    }

    convertParagraphAndTable(props.outputRefEditor, 3, dispatch, tableImages);
  }, [isVisible]);

  return (
    <Container
      sx={{
        padding: matchesSM ? '0 !important' : 'auto',
        margin: 0,
        maxWidth: '100% !important',
        paddingLeft: matchesMD ? 'auto' : '12px !important',
        ...(!matchesMD && { paddingLeft: '27px', paddingRight: '21px' }),
      }}
      ref={visibilityRef}
    >
      <Form.Group controlId="translationOutput">
        <Grid
          item
          container
          xs={12}
          sx={{
            justifyContent: 'space-between',
            marginBottom: '0.2rem !important',
          }}
          id="info-header-input"
        >
          <InfoIcon />
        </Grid>

        <Tooltip
          title={
            hasGroup
              ? props.showTooltipOutput
                ? t('output.tooltip')
                : undefined
              : t('output.hasNoGroup')
          }
          placement="top"
          {...(hasGroup && {
            open: props.showTooltipOutput,
          })}
          disableTouchListener={hasGroup}
          disableInteractive={hasGroup}
          disableFocusListener={hasGroup}
        >
          <Grid
            item
            xs={12}
            sx={{ position: 'relative' }}
          >
            <TextEditorComp
              disableInputField={props.loadingTranslation}
              inputText={props.outputText}
              refEditor={props.outputRefEditor}
              containerStyle={{ margin: 0 }}
              textValue={props.outputTextValue}
              clearEditor={false}
              placeholder={props.placeholder}
              loader={true}
              id={'translation-output'}
              showToolbar={true}
              showFormatIcon={false}
              editable={hasGroup}
              showCounter={transjob.jobId === '-1' ? false : true}
              counterTitle={t('charactercount')}
              ariaLabel={t('textfield_output')}
              textAreaStartingHeight={510}
            />
          </Grid>
        </Tooltip>
      </Form.Group>
    </Container>
  );
};

export default AddImageTextArea;
