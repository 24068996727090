import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import theme from '../../theme/themeConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import AddImageTextArea from '../addImage/AddImageTextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TranslateSelector } from '../../redux/translation/selector';
import { editorValueClear } from '../TextareaField/helper';
import ImageSelectionComp from '../addImage/ImageSelectionComp';

type Props = {
  outputText: any;
  setOutputText(value: any): unknown;
  outputTextValue: any;
};


const AddImagePage = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));

  const [showTooltipOutput, setShowTooltipOutput] = useState(false);

  const outputRefEditor: any = useRef(null);
  useEffect(() => {

    const editorElementInput = document.getElementById('translation-input');

    editorElementInput?.addEventListener('drop', (event) => {
      event.preventDefault();
    });

  }, [outputRefEditor]);

  useEffect(() => {
    if (transjob.jobId === '-1') {
      editorValueClear(outputRefEditor, dispatch);
    }
  }, [outputRefEditor, transjob.jobId]);

  return (
    <Box>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          minHeight: '100vh',
          paddingBottom: '40px', // TODO: This is a temporary fix for the character count being overlayed by the footer
          backgroundColor: theme.palette.primary.main,
          ...(matchesSM
            ? {}
            : {
              paddingLeft: '12px',
              paddingRight: '12px'
            }),
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ padding: '0 !important', margin: 0, maxWidth: '100% !important', height: '100%' }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{ display: 'flex', justifyContent: 'end' }}
          >
            <Grid
              item
              container
              xs={12}
              lg={6}
            >
              <AddImageTextArea
                showTooltipOutput={showTooltipOutput}
                outputText={props.outputText}
                outputTextValue={props.outputTextValue}
                setOutputText={props.setOutputText}
                handleOutputLanguageTypeChange={() => { }}
                outputLanguage={'easy'}
                loadingTranslation={false}
                outputRefEditor={outputRefEditor}
                placeholder={t('output.textarea.placeholder')}
                jobId={transjob.jobId}
                showHighlight={true}
                open={false}
                isUpdated={false}
                setIsUpdated={() => { }}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              lg={6}
              sx={{
                mt: -3
              }}
            >
              <ImageSelectionComp />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default AddImagePage;
