import translationService from 'src/services/translation.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  UPDATE_JOB_STATUS,
  TRANSLATE_LANG,
  NEW_LINE_SET,
  LOADING_TRANS,
  STOP_LOADING_TRANS,
  EMPTY_STATE_VALUE,
  SET_TRANS_CONFIG,
  GET_TRANS_CONFIG,
  UPDATE_TEXT_VALUE,
  UPDATE_SELECTED_OUTPUT_NODE,
  UPDATE_EMPTY_INPUT_VALUE,
  AUTO_LANG_DETECT,
  REGENERATE_TRANSLATION,
  SET_IMAGE_PAGE_VISIBILITY,
} from './types';
import {
  RegenerateTranslateLang,
  TranslateLang,
  UpdateTransJob,
  transConfigGet,
} from 'src/types/translation.types';
import { AxiosResponse } from 'axios';
import Sentry from '../../config/sentryConfig';
import { setQuotSpent, setQuotaLimit } from '../user/actions';
import { updateTableColumn } from 'src/components/TextareaField/helper';
import _ from 'lodash';
import { UPDATE_SEPARATOR_STYLE } from '../user/types';
import { imageEditor } from '../../components/addImage/AddImageTextArea';
import { outputEditor } from '../../components/TextareaField/TextareaField';

export const updateTransJob =
  (jobId: string | number, data: UpdateTransJob) => async (dispatch: any) => {
    try {
      await translationService.updateTranslationJob(jobId, data);
      dispatch({
        type: UPDATE_JOB_STATUS,
        payload: jobId,
      });
      // return appMessageHandling(
      //   dispatch,
      //   'success.job_update_success',
      //   'success'
      // );
    } catch (e: any) {
      return appMessageHandling(dispatch, 'error.job_update_error', 'error');
    }
  };

export const translateLangTrigger =
  (data: TranslateLang) => async (dispatch: any, getState: any) => {
    let stateValue = getState().user.userDetails;
    let inputText = data.orignalInputText;
    const detectedLangVal = getState().translation.autoLangDetect;
    delete data.orignalInputText;
    try {
      dispatch(loading());
      dispatch(emptyStateValueTrans({ transjob: { jobId: '-1' } }));

      // check if user select auto lang detect and the lang value exist
      if (
        data.translationLanguage === 'multi' ||
        detectedLangVal.text !==
          (data.inputText ?? '').replace(/\s*tabindex="\d+"/g, '')
      ) {
        await dispatch(autoDetectLang(detectedLangVal?.text ?? ''));
        data.translationLanguage =
          getState().translation.autoLangDetect?.value ?? 'de';
      }

      const res: AxiosResponse<
        TranslateLang & {
          jobid?: string;
          quotalimit?: number;
          quotaspent?: number;
        }
      > = await translationService.transaltionLag(data);
      if (!res.data || res.data.jobStatus === 'error') {
        throw res.data;
      }
      const { jobid, quotalimit, quotaspent } = res.data;
      localStorage.setItem('jobId', jobid || '');

      let showModal = false;
      if (stateValue?.translatorlicence === true) {
        if (
          quotalimit &&
          quotaspent &&
          (quotalimit - quotalimit / 2 === quotaspent ||
            quotalimit - 3000 === quotaspent)
        ) {
          dispatch(setQuotaLimit(quotalimit));
          dispatch(setQuotSpent(quotaspent));
          showModal = true;
        }
      }
      // dispatch(
      //   updateJobValue({
      //     ...res.data,
      //     originaltext: inputText,
      //     simpletext: res.data.simpleText,
      //     simpletextuseredited: null,
      //     id: res.data.jobid,
      //   })
      // );
      dispatch({
        type: TRANSLATE_LANG,
        payload: { ...res.data, showModal, inputText: inputText },
      });

      // return appMessageHandling(
      //   dispatch,
      //   'success.translate_job_success',
      //   'success'
      // );
    } catch (e: any) {
      if (e?.hasOwnProperty('jobid')) {
        dispatch(
          updateTransJob(e.jobid, {
            jobstatus: 'frontend error: ' + e.message,
          })
        );
      }
      appMessageHandling(
        dispatch,
        e?.response?.data?.detail ?? 'error.translate_job_error',
        'error'
      );
      Sentry.captureMessage(`Translation job create Error: ${e}`, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const getTranslationConfig = () => async (dispatch: any) => {
  try {
    const res: AxiosResponse<transConfigGet> =
      await translationService.getTransConfig();
    dispatch({
      type: GET_TRANS_CONFIG,
      payload: res.data,
    });
  } catch (e: any) {
    return appMessageHandling(dispatch, 'error.get_config__error', 'error');
  }
};

export const setTranslationConfig =
  (data: transConfigGet) => async (dispatch: any) => {
    try {
      const res: AxiosResponse<transConfigGet> =
        await translationService.updateTransConfig(data);
      dispatch({
        type: SET_TRANS_CONFIG,
        payload: res.data,
      });
    } catch (e: any) {
      return appMessageHandling(dispatch, 'error.set_config__error', 'error');
    }
  };

export const newLineSet = (value: boolean) => async (dispatch: any) => {
  try {
    localStorage.setItem('isNewLine', `${value}`);
    dispatch({
      type: NEW_LINE_SET,
      payload: value,
    });
  } catch (e: any) {
    return appMessageHandling(dispatch, 'error.set_newLine__error', 'error');
  }
};

export const updateTranlationText =
  (data: { input_text: string; [key: string]: string }) =>
  async (dispatch: any) => {
    try {
      const { input_text, ...rest } = data;
      let res = await translationService.updateTextOutput(data);
      dispatch({
        type: UPDATE_TEXT_VALUE,
        payload: res.data,
      });

      // update separator style in user details
      dispatch({
        type: UPDATE_SEPARATOR_STYLE,
        payload: rest,
      });
    } catch (e: any) {
      Sentry.captureMessage(
        `Update translation text when separator update Error: ${e}`,
        'error'
      );
    }
  };

export const autoDetectLang =
  (text: string) => async (dispatch: any, getState: any) => {
    try {
      const state =
        getState().translation.transConfig.preferred_translation_language;
      let res = await translationService.autoLangDetect(text);
      dispatch({
        type: AUTO_LANG_DETECT,
        payload: { text, value: res.data.data.language },
      });
      if (
        state === 'multi' &&
        !['en', 'fr', 'de'].includes(res.data.data.language) &&
        localStorage.getItem('auto_detect') !== res.data.data.language
      ) {
        localStorage.setItem('auto_detect', res.data.data.language);
        appMessageHandling(dispatch, 'info.not_supported_lang', 'default', _, {
          autoHideDuration: null,
        });
      }

      if (['en', 'fr', 'de'].includes(res.data.data.language)) {
        localStorage.removeItem('auto_detect');
      }
    } catch (e) {
      Sentry.captureMessage(`Auto detect language Error: ${e}`, 'error');
    }
  };

export const regenerateTranslation =
  (data: RegenerateTranslateLang) => async (dispatch: any) => {
    try {
      dispatch(loading());
      let res = await translationService.regenerateTranslationJob(data);

      return dispatch({
        type: REGENERATE_TRANSLATION,
        payload: res.data,
      });
    } catch (e: any) {
      appMessageHandling(dispatch, e.response.data.detail, 'error');
      Sentry.captureMessage(`Text regeneration Error: ${e}`, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const regeneratedTransFeedback =
  (data: { regenerated_reason: string; id: number | string }) =>
  async (dispatch: any) => {
    try {
      let res = await translationService.sendRegenerateFeedback(data);
      appMessageHandling(
        dispatch,
        'success.create_feedback_success',
        'default'
      );
      return res.data;
    } catch (e: any) {
      appMessageHandling(dispatch, e.response.data.detail, 'error');
      Sentry.captureMessage(`Text regeneration feedback Error: ${e}`, 'error');
    }
  };

export const fileTextExtraction = (file: File) => async (dispatch: any) => {
  try {
    let res = await translationService.fileExtractTextApi({ file });
    return res.data;
  } catch (e: any) {
    appMessageHandling(dispatch, e.response.data.detail, 'error');
    Sentry.captureMessage(`File text extraction Error: ${e}`, 'error');
  }
};
export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING_TRANS,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING_TRANS,
  });
};

export const emptyStateValueTrans = (data: any) => (dispatch: any) => {
  return dispatch({
    type: EMPTY_STATE_VALUE,
    payload: data,
  });
};

export const updateSelectedOutputNode =
  (data: string | null) => (dispatch: any) => {
    return dispatch({
      type: UPDATE_SELECTED_OUTPUT_NODE,
      payload: data,
    });
  };

export const insertImageToTable =
  (content: string) => (dispatch: any, getState: any) => {
    const selectedNode = getState().translation.selectedOutputNode;
    if (!selectedNode) {
      return;
    }

    if (imageEditor) {
      updateTableColumn(imageEditor, content, selectedNode, dispatch);
      // return dispatch(updateSelectedOutputNode(null));
    } else if (outputEditor) {
      updateTableColumn(outputEditor, content, selectedNode, dispatch);
    }
  };

export const updateEmptyInputValue = (value: boolean) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_EMPTY_INPUT_VALUE,
    payload: value,
  });
};

export const setImagePageVisibility =
  (value: boolean) => async (dispatch: any) => {
    return dispatch({
      type: SET_IMAGE_PAGE_VISIBILITY,
      payload: value,
    });
  };
