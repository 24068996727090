import { createSelector } from 'reselect';
import {
  RegeneratedTransJob,
  transConfigGet,
  TransJob,
} from 'src/types/translation.types';

export interface TranslationReducer {
  loadingTrans: boolean;
  transjob: TransJob;
  regeneratedJobs: RegeneratedTransJob[];
  showRefreshPageAlert: boolean;
  newLine: boolean;
  transConfig: null | transConfigGet;
  selectedOutputNode: number;
  emptyInput: boolean;
  autoLangDetect: {
    text: string | null;
    value: string | null;
  };
  disableLikeDislike: {
    [key: string]: boolean;
  };
  showImagePage: boolean;
}
interface RootState {
  translation: TranslationReducer;
}

// This used to memorize the selector value
export const TranslateSelector = createSelector(
  (state: RootState) => state.translation,
  (config: TranslationReducer) => {
    return {
      loadingTrans: config.loadingTrans,
      transjob: config.transjob,
      showRefreshPageAlert: config.showRefreshPageAlert,
      newLine: config.newLine,
      transConfig: config.transConfig,
      selectedOutputNode: config.selectedOutputNode,
      emptyInput: config.emptyInput,
      autoLangDetect: config.autoLangDetect,
      regeneratedJobs: config.regeneratedJobs,
      disableLikeDislike: config.disableLikeDislike,
      showImagePage: config.showImagePage,
    };
  }
);
